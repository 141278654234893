import { forwardRef, useState } from "react";

import Preload from "./preload";

const component = forwardRef ( ( props, ref ) => {
	const {
		lazy = true,
		src,
		og,
		style,
		alt = "Rooter",
		className,
		placeholder = "",
		preload = false,
		onClick,
		...rest
	} = props;

	const [ error, setError ] = useState ( false );

	let imageSrc = !error && src ? src : ( placeholder || og );

	return (

		<>
			<Preload
				preload = { preload }
				src     = { src }
			/>

			<img
				ref       = { ref }
				alt       = { alt }
				className = { className }
				loading   = { lazy && !preload ? "lazy" : "eager" }
				onClick   = { onClick }
				onError   = { () => {
					setError ( true );
				} }
				placeholder = { placeholder }
				src         = { imageSrc }
				style       = { style }
				width       = "100%"
				{ ...rest }
			/>
		</>
	);
} );

component.displayName = "component";

export default component;
