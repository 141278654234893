import { useGetDarkMode } from "_common/hooks/global";

import Presentation from "./presentation";

const component = ( {
	name,
	className,
	size,
	onClick,
	style,
	sameInBothTheme = false,
	...props
} ) => {
	const darkMode = useGetDarkMode ();

	if ( !name ) return null;

	return (
		<Presentation
			className = { className }
			modeType = { darkMode ? "dark" : "light" }
			name = { name }
			onClick = { onClick }
			size = { size }
			style = { style }
			themeBased = { !sameInBothTheme }
			{ ...props }
		/>
	);
};

export default component;
